.home{
    width: 100%;
    text-align: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .main-txt{
        font-size: 36px;
        line-height: 4rem;
        text-transform: capitalize;
        color: var(--dark);
        .grad{
            background-color: var(--fade);
            color: var(--primary);
            padding-right: 5px;
            padding-left: 5px;
            border-radius: 10px;
        }
    }

    .sec-2{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}