.box {
  width: 600px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px -0.083px 2.214px 0px rgba(0, 0, 0, 0.02),
    0px -0.2px 5.32px 0px rgba(0, 0, 0, 0.03),
    0px -0.376px 10.017px 0px rgba(0, 0, 0, 0.04),
    0px -0.67px 17.869px 0px rgba(0, 0, 0, 0.04),
    0px -1.253px 33.422px 0px rgba(0, 0, 0, 0.05);

  padding: 20px 20px;

  .top-box {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--fade);
    border-radius: 10px;
    padding: 10px;
    gap: 5px;
  }
  .tab{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 45px;
    border-radius: 5px;
    cursor: pointer;
  }
  // .tab:hover{
  //   background: rgba(208, 200, 255, 1);
  // }
  .highlight{
    color: #fff;
    background: var(--primary);
    transition: all .4s ease-in-out;
  }

  .bottom-box{
    min-height: 200px;
  }
}
