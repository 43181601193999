@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --primary : #715DE3;
  --fade : rgba(208, 200, 255, 0.44);
  --dark : #1C163C;
  --border : #E2E4E5;
  --txt : #575F6E;
}

.app{
  font-family: 'Inter', sans-serif;
  padding: 20px 40px;
  width: 100%;
  height: 100vh;
}

/*================= Global style =================*/
.tab-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 10px;
  gap: 20px;
}

.i-box {
  width: 100%;
  max-width: 320px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e2e4e5;
  padding: 10px;
}

.icon {
  width: 24px;
  height: 24px;
}

.input-box{
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  color: var(--txt);
}

input::placeholder {
  color: var(--txt);
}

.toggle-box {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--txt);
}

/*========== toggle switch =============*/
/* The switch - the box around the slider */
.switch {
  font-size: 15px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #adb5bd84;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #fff;
  transition: 0.4s;
}

input:checked + .slider {
  background: var(--fade);
  border: 1px solid var(--primary);
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: var(--primary);
}

.create-btn{
    padding: 18px 30px;
    border: none;
    font-size: 16px;
    border-radius: 40px;
    background: #715DE3;
    color: #FFF;
    cursor: pointer;
}