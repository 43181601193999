.login{
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-box{
    width: 100%;
    max-width: 300px;
    padding: 40px;
    box-shadow: 0px -0.083px 2.214px 0px rgba(0, 0, 0, 0.02),
    0px -0.2px 5.32px 0px rgba(0, 0, 0, 0.03),
    0px -0.376px 10.017px 0px rgba(0, 0, 0, 0.04),
    0px -0.67px 17.869px 0px rgba(0, 0, 0, 0.04),
    0px -1.253px 33.422px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 28px;
}

.logo{
    font-weight: 600;
    font-size: 24px;

    .col{
        color: var(--primary);
    }
}

.login-btn{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.272), 0px 0px 1px 0px rgba(0, 0, 0, 0.253);
    border: none;
    background: #fff;
    border-radius: 10px;
    color: var(--txt);
    cursor: pointer;
    border: 1px solid transparent;
}

.login-btn:hover{
    border: 1px solid var(--txt);
    transition: all .4s ease-in-out;
}