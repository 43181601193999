.nav{
    width: 100%;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a{
        color: var(--dark);
        text-decoration: none;
        font-size: 14px;
    }

    .logo{
        font-weight: 600;
        font-size: 20px;

        .col{
            color: var(--primary);
        }
    }

    .navlinks{
        display: flex;
        align-items: center;
        gap: 40px;

        li{
            list-style: none;
        }
    }

    .btn{
        padding: 10px 20px;
        border-radius: 12px;
        border: 1.8px solid #715DE3;
        background-color: transparent;
        color: var(--primary);
        cursor: pointer;
    }
}